import React from 'react';

import Page from '@macanta/components/Page';
import Head from '@macanta/components/Head';

const SecondPage = () => (
  <Page>
    <Head title="Page two" />
    <h1>Components</h1>
    <p>Welcome to the components page.</p>
  </Page>
);

export default SecondPage;
